<template lang="pug">
	div(id="loader-wrapper")
		div(class="logo-container")
			img(class="logo" v-bind:src="getImgUrl('logos/logo-b-vertical.png')" alt="Dagobert Ticketing Logo")
		div(class="text-container")
			h4(class="animate-flicker") Lade...
</template>

<style scoped>

div.logo-container {
	margin: 100px auto 0 auto;
	text-align: center;
}

div.logo-container img.logo {
	width: auto;
	height: auto;
	max-width: 300px;
}

div.text-container {
	text-align: center;
}

div.text-container h4 {
	color: #233642;
    margin: 10px 0;
    font-weight: bold;
    text-transform: uppercase
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

.animate-flicker {
   -webkit-animation: flickerAnimation 2s infinite;
   -moz-animation: flickerAnimation 2s infinite;
   -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}
</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

export default {
	mixins: [ UrlMixin ],
	mounted () {
		this.$nextTick(() => {
			if(window['document']['querySelector']('#loader-wrapper')) {
				window['document']['querySelector']('#loader-wrapper')['style']['height'] = window.innerHeight+'px';
			}
		});
	},
}
</script>