<template lang="pug">
	div(v-bind:id="design == 'default' ? 'page-header-container' : 'page-header-logo-container'")
		div(id="page-header-content" class="valign-wrapper")
			a(v-if="design == 'default'" class="dgbrt-logo" href="#/")
			div(v-if="design == 'diy'" class="icon-header valign-wrapper")
				div(class="row icon-header-row")
					div(class="col offset-m2 m8 offset-s1 s10")
						div(class="icon-header-content")
							div(class="icon")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--01.png')" class="header-logo" alt="DIY Ticketing" title="DIY Ticketing")
							div(class="headline")
								h1 DIY Ticketing
			div(v-if="design == 'custom'" class="icon-header valign-wrapper")
				div(class="row icon-header-row")
					div(class="col offset-m2 m8 offset-s1 s10")
						div(class="icon-header-content")
							div(class="icon")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--01.png')" class="header-logo" alt="DIY Ticketing" title="DIY Ticketing")
							div(class="headline")
								h1 Custom Ticketing
			div(v-if="design == 'in-action'" class="icon-header valign-wrapper")
				div(class="row icon-header-row")
					div(class="col offset-m2 m8 offset-s1 s10")
						div(class="icon-header-content")
							div(class="icon")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--03.png')" class="header-logo" alt="DIY Ticketing" title="DIY Ticketing")
							div(class="headline")
								h1 Best Practice
			div(v-if="design == 'references'" class="icon-header valign-wrapper")
				div(class="row icon-header-row")
					div(class="col offset-m2 m8 offset-s1 s10")
						div(class="icon-header-content")
							div(class="icon")
								img(v-bind:src="getImgUrl('icons/Iconset_DGBRT--04.png')" class="header-logo" alt="DIY Ticketing" title="DIY Ticketing")
							div(class="headline")
								h1 Referenzen

</template>

<style scoped>
#page-header-container {
	position: relative;
	width: 100%;
	padding-top: 26.14%;
	background: linear-gradient(0deg, rgba(29, 103, 101, 0.5), rgba(29, 103, 101, 0.5)), url('../assets/header.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

#page-header-logo-container {
	position: relative;
	width: 100%;
	padding-top: 47.42%;
	background: linear-gradient(0deg, rgba(229, 229, 229, 0.5), rgba(229, 229, 229, 0.5)), url('../assets/header.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

#page-header-content,
.icon-header {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.dgbrt-logo {
	background-image: url('../assets/logo.png');
	background-position: center center;
	background-size: contain;	
	position: relative;
    width: 100%;
    padding-top: 26.4%;
    background-repeat: no-repeat;
    display: block;
}

#page .row.icon-header-row {
	width: 100%;
	margin-bottom: 0;
	margin-left: 0;
}

.icon-header-content {
	text-align: center;
	width: 100%;
}

.icon-header .header-logo {
	width: 50%;
	height: auto;
	max-width: 200px;
}

.icon-header-content .headline h1 {
	text-transform: uppercase;
	font-weight: bold;
	color: #233642;
}

.icon-header-content .headline:after {
	content: '';
	width: 60px;
	height: 6px;
	background: #233642;
	display: block;
	margin: 20px auto;
}

@media screen and (max-width: 998px) {
	#page-header-container,
	.dgbrt-logo {
		padding-top: 40%;
	}
}

@media screen and (max-width: 992px) {
	.icon-header .header-logo {
		max-width: 100px;
	}

	.icon-header-content .headline h1 {
		font-size: 3rem;
		margin: .5rem;
	}
}

@media screen and (max-width: 650px) {
	#page-header-logo-container {
		padding-top: 60%;
	}	
}

@media screen and (max-width: 550px) {
	.icon-header .header-logo {
		max-width: 50px;
	}

	.icon-header-content .headline h1 {
		font-size: 2rem;
		margin: .2rem;
	}

	#page-header-container,
	.dgbrt-logo {
		padding-top: 50%;
	}
}

@media screen and (max-width: 360px) {
	#page-header-logo-container {
		padding-top: 100%;
	}	
}

</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

export default {
	mixins: [ UrlMixin ],
	computed: {
		design() {
			let design = 'default';
			switch(this.$route.path) {
				case '/diy-ticketing':
					design = 'diy';
				break;
				case '/custom-ticketing':
					design = 'custom';
				break;
				case '/ticketing-in-action':
					design = 'in-action';
				break;
				case '/references':
					design = 'references';
				break;
				case '/':
				default:
					design = 'default';
				break;
			}
			return design;
		}
	}
}
</script>