<template lang="pug">
	div(id="page-footer-container")
		div(id="page-footer-content")
			div(class="flex row footer-row hide-on-small-only")
				div(class="col m4 s12 link-col valign-wrapper")
					div
						div
							a(href="#/imprint") Impressum
						div
							a(href="#/privacy") Datenschutz
				div(class="col m4 s12 logo-col valign-wrapper")
					div(class="logo-col-content")
						a(href="#/")
							img(class="footer-logo" src="@/assets/logo.png")
				div(class="col m4 s12 link-col valign-wrapper")
					div
						div
							a(href="#/contact") Kontakt
						div
							a(href="#/ticket-scanner-setup") Ticketscanner
			div(class="flex row hide-on-med-and-up")
				div(class="col s12")
					div(class="small-footer")
						div(class="small-logo")
							a(href="#/")
								img(class="footer-logo" src="@/assets/logo.png")
						div(class="small-link")
							a(href="#/imprint") Impressum
						div(class="small-link")
							a(href="#/privacy") Datenschutz
						div(class="small-link")
							a(href="#/contact") Kontakt
						div(class="small-link")
							a(href="#/ticket-scanner-setup") Ticketscanner
						//-
							div(class="small-link")
								a(href="#/terms") AGB
</template>

<style scoped>
#page-footer-container {
	background-color: #4f5d66;
}

#page-footer-content {
	padding: 50px;
}

#page-footer-container #page-footer-content .footer-row {
	margin-bottom: 0;
}

.link-col div {
	text-align: center;
	margin: 11px auto;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
}

.link-col div a {
	color: #fff;
}

.logo-col {
	text-align: center;
}

.logo-col .logo-col-content {
	width: 100%;
}

.logo-col .logo-col-content .footer-logo {
	width: auto;
	height: auto;
	max-height: 160px;
}

.small-footer {
	text-align: center;
	width: 100%;
}

.small-footer .small-logo img {
	width: auto;
	height: auto;
	max-width: 200px;
}

.small-footer .small-link,
.small-footer .small-link a {
	color: #fff;
}

.small-footer .small-link {
	margin: 10px 0;
}

.small-footer .small-link a {
	text-transform: uppercase;
	font-weight: bold;
}

</style>

<script>
export default {
}
</script>