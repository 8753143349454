<template lang="pug">
	a(href="#/")
		img(class="dgbrt-mini-header-logo" v-bind:src="getImgUrl('logos/logo-b-horizontal.png')")
</template>

<style scoped>
.dgbrt-mini-header-logo {
	width: 250px;
	height: auto;
	padding-bottom: 25px;
}

@media screen and (max-width: 400px) {
	.dgbrt-mini-header-logo {
		width: 200px;
	}
}

@media screen and (max-width: 400px) {
	.dgbrt-mini-header-logo {
		width: 150px;
	}
}
</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

export default {
	mixins: [ UrlMixin ]
}
</script>