<template lang="pug">
	div(id="navi-container")
		div(id="navi-button")
			div(class="navi-toggle" @click="toggleNavi()")
				i(v-if="!naviVisible" v-bind:class="naviButtonClass") menu
				i(v-if="naviVisible" v-bind:class="naviButtonClass") close
		div(v-if="naviVisible" id="navi-overlay")
			div(id="navi-items" class="row")
				div(class="col offset-s1 s10 offset-m3 m6")
					div(class="navi-link")
						a(href="#/" @click="toggleNavi()") Startseite
					div(class="navi-link")
						a(href="#/diy-ticketing" @click="toggleNavi()") DIY Ticketing
					div(class="navi-link")
						a(href="#/custom-ticketing" @click="toggleNavi()") Custom Ticketing
					div(class="navi-link")
						a(href="#/ticketing-in-action" @click="toggleNavi()") Best Practice
					div(class="navi-link")
						a(href="#/references" @click="toggleNavi()") Referenzen
					div(class="navi-link")
						a(href="#/contact" @click="toggleNavi()") Kontakt
</template>

<style scoped>

#navi-container {
	width: 100%;
	height: 0;
}

#navi-container #navi-button {
	position: fixed;
	right: 55px;
	z-index: 1000;
	cursor: pointer;
}

#navi-container #navi-button i {
	font-size: 5em;
}

#navi-overlay {
	background: rgba(0, 0, 0, 0.8);
	width:      100%;
	height:     100%;
	z-index:    999;
	top:        0;
	left:       0;
	position:   fixed;
}

#navi-overlay #navi-items {
	margin-top: 200px;
	color: #fff;
	text-align: center;
}

#navi-overlay #navi-items .navi-link {
	margin-bottom: 20px;
}

#navi-overlay #navi-items .navi-link a {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 2.5em;
	color: #fff;
}

.navi-icon {
	color: #fff;
}

.navi-icon-dark {
	color: #233642;
}

@media screen and (max-width: 600px) {
	#navi-overlay #navi-items .navi-link a {
		font-size: 2em;
	}	
}

@media screen and (max-width: 500px) {
	#navi-container #navi-button {
		right: 30px;
		top: 20px;
	}
}

@media screen and (max-width: 400px) {
	#navi-container #navi-button {
		top: 8px;
	}

	.navi-icon-light {
		margin-top: 12px;
	}
}

@media screen and (max-width: 450px) {
	#navi-overlay #navi-items .navi-link a {
		font-size: 1.5em;
	}	
}
</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

export default {
	mixins: [ UrlMixin ],
	data: function() {
		return {
			naviVisible: false
		}
	},
	computed: {
		naviButtonClass() {
			return {
				'material-icons': true,
				'navi-icon': true,
				'navi-icon-dark': this.$route.path != '/',
				'navi-icon-light': this.$route.path == '/'
			};
		}
	},
	methods: {
		toggleNavi() {
			this.naviVisible = this.naviVisible ? false : true;
		}
	}
}
</script>