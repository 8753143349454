import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/store';

import 'materialize-css/dist/css/materialize.min.css';
import 'material-design-icons/iconfont/material-icons.css';

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#dgbrt-page')
