import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loading: true
	},
	mutations: {
		setLoading(state) {
			state.loading = true;
		},
		unsetLoading(state) {
			state.loading = false;	
		}
	},
	getters: {
		loading: state => {
			return state.loading;
		}
	}
});
