<template lang="pug">
	div(id="page")
		div(v-if="!loading")
			navi
			div(id="page-boundaries")
				div(v-if="$route.path != '/'" class="row row-no-mb")
					div(class="col s12")
						dgbrt-mini-header
				div(class="row")
					div(class="col s12")
						dgbrt-header
				router-view
				div(class="row")
					div(class="col s12")
						dgbrt-footer
		dgbrt-loading(v-else)
</template>

<style>
@font-face {
	font-family: Brandon;
	letter-spacing: 1em;
	src: url('./fonts/BrandonText-Regular.otf') format("opentype");
}

@font-face {
	font-family: Brandon;
	font-weight: bold;
	letter-spacing: 1em;
	src: url('./fonts/BrandonText-Bold.otf') format("opentype");
}

body {
	min-height: 100%;
    width: 100%;
    position: absolute;
	font-family: Brandon;
	letter-spacing: .05em;
}

#page {
	padding: 50px 25px;
	background-color: #208988;
	background-image: url('./assets/background.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: #fff;
	height: 100%;
}

#page #page-boundaries {
	max-width: 1680px;
	margin: 0px auto;
}

#page .row {
	margin-bottom: 50px;
}

#page .row .col {
	padding: 0 25px;
}

#page .row.row-no-mb {
	margin-bottom: 0;
}

.flex,
.flex .col {
	display: flex;
	flex-wrap: wrap;
}

.flex .col div {
	align-items: flex-start;
}

#page ul.bullets li {
	list-style-type: disc;
	margin-left: 25px;
}

.contentbox {
	background-color: rgba(255, 255, 255, 0.7);
	color: #233642;
	padding: 3em 4em;
	font-size: 1.2em;
}

@media screen and (max-width: 992px) {
	#page .row {
		margin-bottom: 25px;
	}

	#page .transparentbox {
		margin-bottom: 25px;
	}

	#page .box-row {
		margin-bottom: 0px;
	}

	.s-mb {
		margin-bottom: 25px;
	}

	.s-no-mb {
		margin-bottom: 0px;
	}
}

@media screen and (max-width: 500px) {
	#page {
		padding: 25px 0;
	}

	#page .row {
		margin-bottom: 25px;
	}
}
</style>

<script>
import M from 'materialize-css';
import Loading from './components/Loading';
import Navi from './components/Navi';
import MiniHeader from './components/MiniHeader';
import Header from './components/Header';
import Footer from './components/Footer';

export default {
	computed: {
		loading() {
			return this.$store.getters.loading;
		}
	},
	mounted () {
		M.AutoInit()
	},
	components: {
		'dgbrt-loading': Loading,
		'navi': Navi,
		'dgbrt-mini-header': MiniHeader,
		'dgbrt-header': Header,
		'dgbrt-footer': Footer
	}
}
</script>